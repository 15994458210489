import { FC, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import StickyBox from 'react-sticky-box';

import { useRequest } from 'ahooks';
import dayjs from 'dayjs';
import cloneDeep from 'lodash/cloneDeep';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import isUndefined from 'lodash/isUndefined';
import set from 'lodash/set';
import useEditCoveragesStore from 'store/useEditCoveragesStore';

import {
  AxiosError,
  FormAndCardViewProductDto,
  PRODUCT_TYPE_API,
  PRODUCT_TYPE_NON_API,
  ProductDetailsDto,
  ProductDto,
  PromoEventBannerDto,
  admin,
  formatTimeFieldsForAPI,
  getLocationTimezone,
} from '@saturn/api';
import { Button, Card, Form, FormProps, FormSubmitFailed, Icon, Spin, notification } from '@saturn/uikit';

import { PageHeader } from 'shared/components';
import { SidebarList } from 'shared/components/SidebarList/SidebarList';
import { DefaultIndexValue, IndicesType } from 'shared/components/SidebarList/types';
import { PROMPT_MESSAGE } from 'shared/constants';
import {
  getVisibleSections,
  onFormFinishFailed,
  revalidateAfterVisibilityChanged,
  useAdminLocation,
  usePrompt,
} from 'shared/utils';

import { ProductTabs, SystemInfo } from 'features/product-landing/components';

import PRODUCT_SECTION_TITLES from '../features/product-landing/constants';
import ExportImport from '../shared/components/ExportImport/ExportImport';
import ImportPageContext from '../shared/contexts/ImportPageContext';
import useExportImportEnabled from '../shared/hooks/useExportImportEnabled';
import downloadJson from '../shared/utils/downloadJson';
import uploadImportedImages from '../shared/utils/processImportedImages';
import { ScrollableSection } from '../types/scrollableSection';

import styles from './styles.module.scss';

const ProductLandingPage: FC = () => {
  const { productId = '' } = useParams();

  const [sectionsVisibility, setSectionsVisibility] = useState<Record<string, boolean | null>>({});
  const [defaultIndices, setDefaultIndices] = useState<Record<string, DefaultIndexValue>>({});
  const [scrollableSections, setScrollableSections] = useState<Record<string, ScrollableSection>>({});
  const [resetIndices, setResetIndicies] = useState(false);
  const [indices, setIndices] = useState<IndicesType>([]);
  const [selectedProducts, updateSelectedProducts] = useState<string[]>([productId]);
  const [isShowLeaveModal, setShowLeaveModal] = useState<boolean>(false);
  const [selectedInsurancePartners, updateSelectedInsurancePartners] = useState<string[]>([]);
  const [showDoYouWantToImportModal, setShowDoYouWantToImportModal] = useState<boolean>(false);
  const [isImportModalOpen, setIsImportModalOpen] = useState<boolean>(false);

  const location = useLocation();
  const navigate = useNavigate();
  const { locationLanguage, adminLocation } = useAdminLocation();
  const { coveragesState, setCoveragesState, validateCoverageFormValues } = useEditCoveragesStore();

  const [formProduct] = Form.useForm<ProductDto>();
  const [formDetails] = Form.useForm<ProductDetailsDto>();

  usePrompt(PROMPT_MESSAGE, isShowLeaveModal);

  const disableProducts = (newSelectedProducts: FormAndCardViewProductDto[]) => {
    const result = [
      ...new Set([
        productId,
        ...newSelectedProducts.map((item: { productId: string }) => item?.productId).filter((id: string) => id),
      ]),
    ] as string[];

    updateSelectedProducts(result);
  };

  const disableProviders = (insuranceProviderIds: string[]) => {
    const providers = insuranceProviderIds;
    const result = [...new Set(providers)] as string[];
    updateSelectedInsurancePartners(result);
  };

  //<editor-fold desc="API requests">
  const { data: locationProducts = [] } = useRequest(() => admin.getProductForLocation(adminLocation.id), {
    refreshDeps: [adminLocation.id],
  });

  const { data: product, loading: productLoading } = useRequest(() => admin.getProduct(productId), {
    refreshDeps: [productId],
    onSuccess: data => {
      if (data) {
        formProduct.setFieldsValue(data);
      }
    },
    onError: () => {
      const [, locationInPath, products] = location.pathname.split('/');
      navigate(`/${locationInPath}/${products}`, { replace: true });
    },
  });

  const setFormDetailsFromResponse = (data: ProductDetailsDto) => {
    const promoEventBannerCopy = {
      ...data.promoEventBanner,
    };
    if (data?.promoEventBanner) {
      // Map DTO to form fields and apply market timezone
      const locationTimezone = getLocationTimezone(adminLocation.id);

      const zonedExpiryDate = data.promoEventBanner.bannerExpiryDate
        ? dayjs(data.promoEventBanner.bannerExpiryDate).utcOffset(locationTimezone)
        : null;
      const zonedStartDate = data.promoEventBanner.bannerStartDate
        ? dayjs(data.promoEventBanner.bannerStartDate).utcOffset(locationTimezone)
        : null;

      promoEventBannerCopy.bannerExpiryDate = zonedExpiryDate?.format('YYYY-MM-DD') ?? null;
      promoEventBannerCopy.bannerExpiryTime = zonedExpiryDate ?? null;

      promoEventBannerCopy.bannerStartDate = zonedStartDate?.format('YYYY-MM-DD') ?? null;
      promoEventBannerCopy.bannerStartTime = zonedStartDate ?? null;
      data.promoEventBanner = promoEventBannerCopy as PromoEventBannerDto;
    }
    formDetails.setFieldsValue({
      ...data,
      // Only visibility part of the coverage state should be saved to antd form
      // Other part is handled by the custom storage
      ...(data.allYouNeedToKnow && {
        allYouNeedToKnow: {
          ...data.allYouNeedToKnow,
          subSections: {
            ...data.allYouNeedToKnow.subSections,
            coverage: {
              visible: data.allYouNeedToKnow.subSections.coverage.visible,
              order: data.allYouNeedToKnow.subSections.coverage.order,
            },
          },
        },
      }),
    });
    if (data.allYouNeedToKnow) {
      setCoveragesState({
        ...data.allYouNeedToKnow.subSections.coverage,
        sectionTitleTouched: false,
        subSections: data.allYouNeedToKnow.subSections.coverage.subSections.map(subS => ({
          ...subS,
          key: window.crypto.randomUUID(),
          coverage: subS.coverage.map(c => ({ ...c, key: window.crypto.randomUUID(), titleTouched: false })),
        })),
      });
    }
    if (data.categoryProducts) {
      updateSelectedProducts(data.categoryProducts.products.map(pr => pr.productId));
    }
  };

  const { data: landing, loading: productDetailsLoading } = useRequest(
    () => admin.getProductDetails(productId, locationLanguage),
    {
      refreshDeps: [productId, locationLanguage],
      onSuccess: data => {
        if (data) {
          setFormDetailsFromResponse(data);
        }
      },
    },
  );

  const isSuperSectionVisible = formDetails.getFieldValue(['allYouNeedToKnow', 'visible']);
  useEffect(() => {
    if (!isSuperSectionVisible) {
      formDetails.setFieldValue(['allYouNeedToKnow', 'subSections', 'whatIsCovered', 'visible'], false);
      formDetails.setFieldValue(['allYouNeedToKnow', 'subSections', 'whoIsCovered', 'visible'], false);
      formDetails.setFieldValue(['allYouNeedToKnow', 'subSections', 'coverage', 'visible'], false);
      formDetails.setFieldValue(['allYouNeedToKnow', 'subSections', 'faq', 'visible'], false);
      formDetails.setFieldValue(['allYouNeedToKnow', 'subSections', 'document', 'visible'], false);
    }
  }, [isSuperSectionVisible]);

  /* this useEffect is for setting up the sidebar sections properly */
  useEffect(() => {
    if (landing) {
      setSectionsVisibility(getVisibleSections(formDetails.getFieldsValue()));
      const defIndices = Object.fromEntries(
        Object.entries(landing).map(([key, value]) => [
          key,
          { order: value?.order ?? 0, subSections: value?.subSections ?? null },
        ]),
      );

      const scrollableSections = Object.fromEntries(
        Object.entries(landing)
          .filter(([, value]) => value && typeof value === 'object' && typeof value?.visible === 'boolean')
          .map(([key, value]) => {
            return [key, { sectionId: value?.sectionId ?? '', sectionName: value?.sectionName ?? '' }];
          }),
      );

      setScrollableSections(scrollableSections);
      setDefaultIndices(defIndices);
    }
  }, [landing, formDetails, isSuperSectionVisible]);

  const { runAsync: updateProduct } = useRequest(admin.updateProduct, { manual: true });
  const { runAsync: updateLanding } = useRequest(admin.updateProductDetails, { manual: true });
  //</editor-fold>

  const { data: providers = [], loading: loadingProviders } = useRequest(
    async () => (await admin.getProvidersList(adminLocation?.id)).map(({ title, id }) => ({ label: title, value: id })),
    {
      refreshDeps: [adminLocation?.id],
    },
  );

  const productLandingId = landing?.id ?? '';

  const {
    runAsync: importPage,
    loading: importLoading,
    data: importedPageData,
  } = useRequest((form: FormData) => admin.importProductLandingPage({ productLandingId, productLandingType, form }), {
    manual: true,
    onSuccess: response => {
      if (response.content.landingContent) {
        setResetIndicies(true);
        // the problem is in ContentBlockContainer it does need list to be present to actually show the data in form we need copy because in the set function something can be mutating values in place
        const landingContentCopy = cloneDeep(response.content.landingContent);
        setFormDetailsFromResponse({ ...landingContentCopy });
        setTimeout(() => {
          setFormDetailsFromResponse({ ...landingContentCopy });
        }, 1000);
        setIsImportModalOpen(false);
        if (response.isFullContent) {
          notification.success({
            message: 'Page imported successfully',
          });
        } else {
          notification.warning({
            message: 'Warning: Content was not fully imported. Please review the page carefully before publishing',
          });
        }
      }
    },
    onError: (e: Maybe<AxiosError>) => {
      if (e.response.data.errors?.length) {
        setIsImportModalOpen(true);
        setShowDoYouWantToImportModal(false);
      }
    },
  });

  // eslint-disable-next-line sonarjs/cognitive-complexity
  const formFinished = async () => {
    try {
      validateCoverageFormValues();
      const productData = await formProduct.validateFields();
      const landingData = await formDetails.validateFields();

      if (coveragesState) {
        landingData.allYouNeedToKnow.subSections.coverage = {
          // Patch coverage block with custom storage values
          ...coveragesState,
          // Keep visible and order handled by the antd form
          sectionId: landingData.allYouNeedToKnow.subSections.coverage.sectionId,
          sectionName: landingData.allYouNeedToKnow.subSections.coverage.sectionName,
          visible: landingData.allYouNeedToKnow.subSections.coverage.visible,
          order: landingData.allYouNeedToKnow.subSections.coverage.order,
        };
      }

      const landingDataWithFormattedTimeFields = formatTimeFieldsForAPI(landingData);

      const landingDataWithOrder = indices
        .filter(
          ([x]) =>
            !['referFriend', 'nextSteps', 'promotionBenefit', 'highlightPlan', 'planLegalDisclaimer'].includes(x),
        )
        .reduce((res, item, i) => {
          const itemKey: string = item[0];
          // @ts-ignore
          const block = res[itemKey];
          if (block && typeof block == 'object') {
            // @ts-ignore
            block.order = i;
          }
          if ('subSections' in block) {
            const indItem = indices.find(ind => ind[0] === itemKey);
            const itemSubSections = indItem && indItem[1]?.subSections;
            itemSubSections?.forEach((subSection, subSectionIndex) => {
              block.subSections[subSection[0]].order = subSectionIndex;
            });
          }
          return res;
        }, landingDataWithFormattedTimeFields);

      if (product && locationLanguage) {
        // handle imported images
        const importImages = importedPageData?.content.landingMediaFiles;
        if (importImages?.length) {
          await uploadImportedImages(importImages, landingDataWithOrder, results => {
            results.forEach(result => {
              set(landingDataWithOrder, result.pathToId, {
                id: result.id,
                mimeType: result.fileMetadata?.mimeType ?? null,
                title: result?.fileMetadata?.title ?? null,
              });
            });
          });
        }
        setShowLeaveModal(false);
        await Promise.all([
          updateProduct(productData),
          updateLanding(productId, locationLanguage, {
            ...landingDataWithOrder,
            info: {
              ...landingDataWithOrder.info,
              marketingName:
                landingDataWithOrder.info.marketingName === '' ? null : landingDataWithOrder.info.marketingName,
            },
            type: productData.type,
            landingType: landing?.landingType,
            pageSummary: {
              ...landingDataWithOrder.pageSummary,
              sections: landingDataWithOrder.pageSummary?.sections.map(section => {
                if (isEmpty(section) || isUndefined(section.sectionId)) {
                  return { sectionId: '' };
                }
                return section;
              }),
            },
          }),
        ]).then(values => {
          if (values.some(value => !isNil(value))) {
            setShowDoYouWantToImportModal(false);
            notification.success({
              message: 'Product has been successfully updated',
            });
          }
          return null;
        });
      }
      return true;
    } catch (e) {
      onFormFinishFailed(e as FormSubmitFailed);
    }
  };

  const onFieldsChange: FormProps['onFieldsChange'] = (changedFields, allFields) => {
    if (!isShowLeaveModal) {
      setShowLeaveModal(true);
    }
    revalidateAfterVisibilityChanged(formDetails, changedFields, allFields);
  };

  const pageTitle = product?.name ?? 'Loading...';
  const envId = window.__RUNTIME_CONFIG__.ENVIRONMENT_ID ?? 'e1';
  const exportName = `${adminLocation.id}_${locationLanguage}_Product_${pageTitle}_${envId}_${new Date().getTime()}`;

  const productLandingType = landing?.landingType ?? '';

  const { runAsync: exportPage, loading: exportLoading } = useRequest(
    () => admin.exportProductLandingPage({ productLandingId, productLandingType, fileName: exportName }),
    {
      manual: true,
      onSuccess: async base64Data => {
        downloadJson(base64Data, exportName);
        notification.success({
          message: 'Page exported successfully',
        });
      },
    },
  );

  const isExportImportEnabled = useExportImportEnabled();

  const landingInitialValues = importedPageData?.content.landingContent ?? {
    ...landing,
    pageSummary: {
      sections:
        landing?.pageSummary?.sections && landing?.pageSummary?.sections.length > 1
          ? landing?.pageSummary?.sections
          : [
              {
                sectionId: '',
              },
              {
                sectionId: '',
              },
              {
                sectionId: '',
              },
            ],
    },
  };

  return (
    <main>
      <PageHeader title={pageTitle} subTitle="Edit Product" />
      <Form.Provider onFormFinish={formFinished}>
        <div className={styles.stickyContainer}>
          <div className={styles.content}>
            <Spin spinning={productLoading && productDetailsLoading} indicator={<Icon name="sync-outline" />}>
              {isExportImportEnabled && (
                <ExportImport
                  importLoading={importLoading}
                  exportLoading={exportLoading}
                  onExportPage={exportPage}
                  onImportPage={importPage}
                  hasUnsavedChanges={isShowLeaveModal || showDoYouWantToImportModal}
                  onImportFinished={setShowDoYouWantToImportModal}
                  isImportModalOpen={isImportModalOpen}
                  setIsImportModalOpen={setIsImportModalOpen}
                  onSaveChangesAndExport={async () => {
                    const submitSuccess = await formFinished();
                    if (submitSuccess) {
                      await exportPage();
                    }
                  }}
                />
              )}
              {product && (
                <Form
                  name="product"
                  form={formProduct}
                  onFinishFailed={onFormFinishFailed}
                  preserve={false}
                  onFieldsChange={onFieldsChange}
                  initialValues={product}
                >
                  <SystemInfo
                    providerId={product?.providerId}
                    type={product?.type}
                    disabled={true}
                    isSlugRequiredForExtendedProduct={landing?.landingType === 'EXTENDED'}
                    providers={providers}
                    loadingProviders={loadingProviders}
                  />
                </Form>
              )}
              {landing && (
                <Form
                  name="productDetails"
                  form={formDetails}
                  onFinishFailed={onFormFinishFailed}
                  preserve={false}
                  onFieldsChange={onFieldsChange}
                  onValuesChange={(changedValues, allValues) => {
                    if (changedValues.categoryProducts) {
                      disableProducts(allValues.categoryProducts.products);
                    } else if (changedValues.insurancePartners) {
                      disableProviders(allValues.insurancePartners.insuranceProviderIds);
                    }
                    setSectionsVisibility(getVisibleSections(allValues));
                  }}
                  initialValues={landingInitialValues}
                >
                  <ImportPageContext.Provider value={importedPageData}>
                    <ProductTabs
                      type={product?.type ?? PRODUCT_TYPE_API}
                      isShowLeaveModal={isShowLeaveModal}
                      sectionTitles={PRODUCT_SECTION_TITLES}
                      isSecondaryFooterDisclosureVisible={!!landing?.secondaryFooterDisclosure}
                      landingType={landing?.landingType}
                      productLandingId={landing?.id}
                      providers={providers}
                      isEdit={true}
                      products={locationProducts.filter(({ id }) => id !== productId)}
                      selectedProducts={selectedProducts}
                      selectedInsurancePartners={selectedInsurancePartners}
                      scrollableSections={scrollableSections}
                    />
                  </ImportPageContext.Provider>
                </Form>
              )}
            </Spin>
          </div>
          <StickyBox offsetTop={20} offsetBottom={20} className={styles.stickyBlock}>
            <Button type="primary" htmlType="submit" onClick={formFinished}>
              Save
            </Button>
            {product?.type === PRODUCT_TYPE_API ||
            (product?.type === PRODUCT_TYPE_NON_API && landing?.landingType === 'EXTENDED') ? (
              <Card title="Page Layout" bordered={false} className={styles.sidebarCardWrapper}>
                <SidebarList
                  parent="product"
                  resetIndices={resetIndices}
                  setResetIndicies={setResetIndicies}
                  sidebarListValues={sectionsVisibility}
                  sectionTitles={PRODUCT_SECTION_TITLES}
                  defaultIndices={defaultIndices}
                  indices={indices}
                  setIndices={setIndices}
                />
              </Card>
            ) : null}
          </StickyBox>
        </div>
      </Form.Provider>
    </main>
  );
};
export default ProductLandingPage;
